/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import { loadNotifications } from '../../../config/axiosConfig/notification/NotificationApi';
import {
  loadNotificationsAlertsDone,
  setIsLoadingNotifications,
  setNotifications,
  setTotalUnreadNotifications,
} from '../../../store/content/NotificationContentEvents';

interface LoadNotificationInterface {
  unread?: boolean;
  priority?: boolean;
  pageNumber?: number;
  pageSize?: number;
  isUpdated?: boolean;
}

export const execute = async ({
  priority,
  pageNumber,
  pageSize,
  unread,
}: LoadNotificationInterface): Promise<void> => {
  const log = useLogService();
  setIsLoadingNotifications(true);
  if (pageNumber === 0) setNotifications({ notifications: [] });
  return loadNotifications(unread, priority, pageNumber, pageSize)
    .then((response: any) => {
      const data = response?.data;
      if (data) {
        const { content, pagination, notRead } = data;
        setTotalUnreadNotifications(notRead || 0);
        loadNotificationsAlertsDone({
          notifications: content,
          pagination,
          notRead,
        });
        log.info('LoadNotificationService - load alerts');
      }
    })
    .catch((error) => {
      log.error('LoadNotificationService: ', error?.message || 'unexpected error founded');
      setIsLoadingNotifications(false);
    });
};

export default execute;
