import { createStore } from 'effector';

import { INotification } from 'components/Notification/notification-dropdown/notification-item/NotificationItemTypes';
import {
  loadNotificationsAlertsDone,
  setIsLoadingNotifications,
  setNotifications,
  setTotalUnreadNotifications,
  updatePaginationDone,
} from './NotificationContentEvents';
import { NotificationItemStore } from './NotificationItem';

const initialState = {
  notifications: [],
  notRead: 0,
  pagination: {
    pageSize: 10,
    pageNumber: 0,
    total: 0,
  },
  isLoading: false,
} as NotificationItemStore;

const NotificationContentStore = createStore(initialState)
  .on(setIsLoadingNotifications, (state, isLoading) => ({ ...state, isLoading }))
  .on(loadNotificationsAlertsDone, (state, { notifications, pagination, notRead }) => ({
    ...state,
    isLoading: false,
    notifications:
      pagination?.pageNumber === 0
        ? notifications
        : [
            ...(state.notifications as Array<INotification>),
            ...(notifications as Array<INotification>),
          ],
    notRead,
    pagination,
  }))
  .on(updatePaginationDone, (state, pagination) => ({
    ...state,
    pagination,
    notifications: [],
  }))
  .on(setTotalUnreadNotifications, (state, notRead) => ({
    ...state,
    notRead,
  }))
  .on(setNotifications, (state, { notifications }) => ({
    ...state,
    notifications,
  }));

export default NotificationContentStore;
