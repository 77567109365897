import { Tool } from 'models/Tool';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { appCatalogService } from 'services/AppCatalogService/AppCatalogService';

const useGetTools = () => {
  const { locale } = useIntl();
  const [tools, setTools] = useState<Tool[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const fetchTools = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      setHasError(false);

      const response = await appCatalogService.getApps({ params: { includeApps: true }, locale });
      setTools(response.data);
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [locale]);

  useEffect(() => {
    fetchTools();
  }, [fetchTools]);

  return { tools, loading, hasError, fetchTools };
};

export default useGetTools;
