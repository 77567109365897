import { convertToPascalCase } from 'helpers';
import { StyledIconContainer } from './AppIcon.styles';
import { iconsMap } from './icons';

interface AppIconProps {
  icon: string;
  dataTestId?: string;
}

export const getIcon = (icon: string) => {
  const applicationIcon = iconsMap.get(convertToPascalCase(icon));

  return applicationIcon?.icon ?? <></>;
};

const AppIcon = ({ icon, dataTestId }: AppIconProps): JSX.Element => {
  const IconComponent = getIcon(icon);

  return <StyledIconContainer data-testid={dataTestId}>{IconComponent}</StyledIconContainer>;
};

export default AppIcon;
