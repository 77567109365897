import { LoadingDots } from '@hexa-ui/components';
import { Button, Dimmer, Typography } from '@hexa-ui/components2';
import { EmptyState } from '@hexa-ui/illustrations';
import AppIcon from 'components/AppIcon/AppIcon';
import { AppMenuItem } from 'hooks';
import useGetTools from 'hooks/useGetTools/useGetTools';
import { RefObject, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  ButtonContainer,
  CategoryTitle,
  Column,
  Container,
  ExceptionContainer,
  MenuButton,
  MenuItem,
} from './AppSwitcher.styles';

type CategoryApps = {
  identifier: string;
  name: string;
  icon: string;
  appRoute: string;
};

type userApps = {
  name: string;
  apps: CategoryApps[];
};

type AppSwitcherProps = {
  appsWithScope: AppMenuItem[];
  containerRef: RefObject<HTMLDivElement>;
  handleClose: () => void;
  open: boolean;
};

const AppSwitcher = ({ appsWithScope, containerRef, handleClose, open }: AppSwitcherProps) => {
  const { tools, loading, hasError, fetchTools } = useGetTools();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
      containerRef.current?.focus();
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open, containerRef]);

  const allowedUserApps = useMemo(() => {
    const groupedMatches = tools.reduce((acc, appGroup) => {
      appGroup.apps.forEach((app: CategoryApps) => {
        const match = appsWithScope.find((allowedApp) => allowedApp.appId === app.identifier);
        if (match) {
          if (!acc[appGroup.name]) {
            acc[appGroup.name] = [];
          }
          acc[appGroup.name].push(app);
        }
      });

      return acc;
    }, {});

    return Object.entries(groupedMatches)
      .map(([name, apps]) => ({
        name,
        apps,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [appsWithScope, tools]);

  if (!open) {
    return null;
  }

  if (hasError) {
    return (
      <>
        <Container tabIndex={-1} data-testid="appSwitcher-overlay" ref={containerRef}>
          <ExceptionContainer>
            <EmptyState.SomethingWentWrong width={128} height={128} />
            <Typography.Body style={{ textAlign: 'center' }}>
              {formatMessage({ id: 'AppSwitcher.error' })}
            </Typography.Body>
          </ExceptionContainer>
          <ButtonContainer>
            <Button variant="secondary" onClick={fetchTools}>
              {formatMessage({ id: 'AppSwitcher.reload' })}
            </Button>
          </ButtonContainer>
        </Container>
        <Dimmer style={{ zIndex: 350 }} />
      </>
    );
  }

  if (loading) {
    return (
      <>
        <Container tabIndex={-1} data-testid="appSwitcher-overlay" ref={containerRef}>
          <ExceptionContainer>
            <LoadingDots data-testid="loading-dots" size="xxlarge" />
          </ExceptionContainer>
        </Container>
        <Dimmer style={{ zIndex: 350 }} />
      </>
    );
  }

  return (
    <>
      <Container tabIndex={-1} data-testid="appSwitcher-overlay" ref={containerRef}>
        {allowedUserApps.map((category: userApps) => (
          <Column key={category.name}>
            <CategoryTitle variant="h6">{category.name}</CategoryTitle>
            {category.apps.map((app) => (
              <MenuButton to={app.appRoute} onClick={handleClose} key={app.identifier}>
                <AppIcon icon={app.icon} />
                <MenuItem variant="large" style={{ fontWeight: 500 }}>
                  {app.name}
                </MenuItem>
              </MenuButton>
            ))}
          </Column>
        ))}
      </Container>
      <Dimmer style={{ zIndex: 350 }} />
    </>
  );
};

export default AppSwitcher;
