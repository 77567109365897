import { styled } from '@hexa-ui/theme';

export const StyledIconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$full',
  width: '1.5rem',
  height: '1.5rem',
  '& svg': {
    width: '1.5rem',
    height: '1.5rem',
  },
});
