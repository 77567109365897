import { useStore } from 'effector-react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useEffect, useRef, useState } from 'react';
import NotificationDropdown from './notification-dropdown/NotificationDropdown';
import NotificationsBell from './notificationsBell/NotificationsBell';
import { execute as LoadNotificationService } from './services/notification/loadNotification/loadNotificationService';
import { execute as ResetPaginationService } from './services/notification/resetPagination/resetPaginationService';
import NotificationContentStore from './store/content/NotificationContentStore';

const Notification = () => {
  const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] = useState(false);
  const {
    pagination,
    pagination: { pageSize },
  } = useStore(NotificationContentStore);
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => {
    setIsNotificationsDropdownOpen(false);
    ResetPaginationService(pagination);
  });

  useEffect(() => {
    LoadNotificationService({ pageSize, pageNumber: 0 });
  }, []);

  const toggleNotificationDropdown = () => {
    ResetPaginationService(pagination);
    setIsNotificationsDropdownOpen(!isNotificationsDropdownOpen);
  };

  return (
    <>
      <NotificationsBell openNotificationDropdown={toggleNotificationDropdown} />
      {isNotificationsDropdownOpen && (
        <div ref={containerRef}>
          <NotificationDropdown />
        </div>
      )}
    </>
  );
};

export default Notification;
