import { SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

type NotificationItemSkeletonProps = {
  quantity: number;
};
export default function NotificationItemSkeleton({
  quantity,
}: NotificationItemSkeletonProps): JSX.Element {
  return (
    <>
      {Array.from(Array(quantity).keys()).map((_, index) => (
        <StyledNotificationSkeleton key={index} data-testid="card-skeleton">
          <StyledNotificationHeaderSkeleton>
            <SkeletonLoader width="40px" height="40px" variant="image" imageShape="circle" />
            <StyledNotificationSubheaderSkeleton>
              <SkeletonLoader imageShape="rectangle" variant="body" width="8.75rem" height="1rem" />
              <SkeletonLoader imageShape="rectangle" variant="body" width="6rem" height="1rem" />
            </StyledNotificationSubheaderSkeleton>
          </StyledNotificationHeaderSkeleton>
          <StyledNotificationContentSkeleton>
            <SkeletonLoader imageShape="rectangle" variant="body" width="100%" height="1rem" />
            <SkeletonLoader imageShape="rectangle" variant="body" width="100%" height="1rem" />
            <SkeletonLoader imageShape="rectangle" variant="body" width="16.5rem" height="1rem" />
            <SkeletonLoader imageShape="rectangle" variant="body" width="5.5rem" height="1rem" />
          </StyledNotificationContentSkeleton>
        </StyledNotificationSkeleton>
      ))}
    </>
  );
}

const StyledNotificationSkeleton = styled('div', {
  height: '10.75rem',
  width: '100%',
  padding: '1rem',
  borderBottom: '1px solid #DBDADA',
});
const StyledNotificationHeaderSkeleton = styled('div', {
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  alignItems: 'center',
  height: '40px',
});
const StyledNotificationSubheaderSkeleton = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: 'inherit',
  marginLeft: '0.5rem',
});
const StyledNotificationContentSkeleton = styled('div', {
  alignItems: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  paddingLeft: '3rem',
  marginTop: '0.5rem',
  ':last-child': {
    marginTop: '0.5rem',
  },
});
