import { Avatar } from '@hexa-ui/components';
import { AppGrid, User } from '@hexa-ui/icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AppMenuItem } from 'hooks';
import { Suspense, useMemo, useRef, useState } from 'react';
import { getCurrentLogo } from 'utils/logo-utils/logoUtils';
import { useAuthContext, useEnvContext } from '../../contexts';
import { toggleScrollbar } from '../../utils';
import { AvatarMenu } from './components';

import { DropdownButton } from '@hexa-ui/components2';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useIntl } from 'react-intl';
import Notification from '../Notification/Notification';
import {
  StyledAvatarButton,
  StyledContainer,
  StyledHeader,
  StyledLabel,
  StyledLink,
  StyledLogo,
  StyledTopBarNav,
} from './TopBarNav.styles';
import AppSwitcher from './components/AppSwitcher/AppSwitcher';

export const TopBarNav = ({ apps }: { apps: AppMenuItem[] }): JSX.Element => {
  const [isSwitcherOpen, setIsSwitcherOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const appsWithScope = useMemo(() => apps.filter((app) => !app.hidden), [apps]);
  const { customization } = useAuthContext();
  const envContextValues = useEnvContext();
  const appSwitcherContentRef = useRef(null);
  const appSwitcherButtonRef = useRef(null);

  const handleClose = () => {
    setIsSwitcherOpen(false);
  };

  useOnClickOutside(appSwitcherContentRef, handleClose, [appSwitcherButtonRef]);

  const logo = getCurrentLogo(envContextValues.defaultLogo);

  return (
    <>
      <AppSwitcher
        open={isSwitcherOpen}
        appsWithScope={appsWithScope}
        containerRef={appSwitcherContentRef}
        handleClose={handleClose}
      />
      <StyledTopBarNav>
        <StyledHeader>
          <StyledContainer>
            <DropdownButton
              aria-label="desktop-app-switcher-button"
              ref={appSwitcherButtonRef}
              open={isSwitcherOpen}
              onClick={() => setIsSwitcherOpen((open) => !open)}
              style={{ marginRight: '1rem' }}
              size="small"
              icon={() => <AppGrid />}
            >
              <StyledLabel style={{ fontWeight: 500 }}>
                {formatMessage({ id: 'AppSwitcher.tools' })}
              </StyledLabel>
            </DropdownButton>
            <StyledLink
              to="/"
              aria-label="home link"
              variantLogo={envContextValues.defaultLogo || 'beesOne'}
            >
              {customization?.logo ? (
                <StyledLogo
                  src={customization.logo}
                  alt={`Bees ${customization.theme}`}
                  data-testid="customization-logo"
                />
              ) : (
                <StyledLogo src={logo.src} alt={logo.alt} />
              )}
            </StyledLink>
          </StyledContainer>
          <StyledContainer>
            <Suspense fallback="">
              <Notification />
            </Suspense>
            <DropdownMenu.Root onOpenChange={toggleScrollbar}>
              <DropdownMenu.Trigger asChild>
                <StyledAvatarButton aria-controls="topBar-menu" aria-haspopup="true">
                  <Avatar background="primary" size="xlarge" variantType="image">
                    <User size="large" />
                  </Avatar>
                </StyledAvatarButton>
              </DropdownMenu.Trigger>
              <AvatarMenu />
            </DropdownMenu.Root>
          </StyledContainer>
        </StyledHeader>
      </StyledTopBarNav>
    </>
  );
};
